export const environment = {
    production: true,
    firebase: {
        apiKey: 'AIzaSyDfiSPUlcjf-NEqqL7MECt_q05Eq9EB-HE',
        authDomain: 'listique-site-prod.firebaseapp.com',
        databaseURL: 'https://listique-site-prod.firebaseio.com',
        projectId: 'listique-site-prod',
        storageBucket: 'listique-site-prod.appspot.com',
        messagingSenderId: '23885857293',
        appId: '1:23885857293:web:37b6f21df28504f8'
    },
};
